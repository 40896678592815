import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import PhotoGrid from '../components/PhotoGrid';
import BlogItem from '../components/BlogItem';
import microphone from '../img/microphone-charcoal.svg'
import locate from '../img/locate-outline.svg';
import newspaper from '../img/newspaper-outline.svg';

export const IndexPageTemplate = ({
  image,
  title,
  subtitle,
  heading,
  podcastbanner,
  mainpitch,
  quoteimage,
  quotation,
  description,
  intro,
  posts
}) => (
  <div>
    <div
      className="full-width-image margin-top-0 homepage-hero"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
        backgroundSize: `cover`
      }}>
      <div
        style={{
          display: 'flex',
          height: 'auto',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column'
        }}>
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            color: 'white',
            lineHeight: '1',
            padding: '0.25em'
          }}>
          {title}
        </h1>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            color: 'white',
            lineHeight: '1',
            padding: '0.25em'
          }}>
          {subtitle}
        </h3>
      </div>
    </div>

    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <div className="content">
                <div className="content has-text-centered">
                  <div className="fancyHR">
                    <img
                        src={locate}
                        alt="Locate"
                        style={{ width: 'auto', height: '2em' }}
                      />
                    <hr/>
                  </div>
                    <h2 className="has-text-weight-bold is-size-4-mobile is-size-4-tablet is-size-3-widescreen">{mainpitch.title}</h2>
                    <p>{mainpitch.description}</p>
                </div>
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section section--fullwidth" id="podcastBannerSection">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-5 is-offset-1" id="podcastCoverColumn">
              <div className="content">
                <img src={podcastbanner.image.publicURL} alt={podcastbanner.alt} />
              </div>
            </div>
            <div className="column is-5 is-offset-1" id="podcastDetailsColumn">
              <div className="content">
                <div className="fancyHR">
                  <img
                      src={microphone}
                      alt="Podcast"
                      style={{ width: 'auto', height: '2em' }}
                    />
                  <hr/>
                </div>
                
                <h3
                  className="has-text-weight-bold is-size-4-mobile is-size-4-tablet is-size-3-widescreen"
                  style={{
                    color: '#333',
                    lineHeight: '1',
                    padding: '0.25em'
                  }}>
                    {podcastbanner.title}
                </h3>
                <p className="subtitle">{podcastbanner.description}</p>
                <Link className="btn" to="/podcast">
                      Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section" id="homepage-quotation">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-3 is-offset-1">
              <div className="content">
                <div className="content has-text-centered">
                  <img
                      src={quoteimage.image.publicURL}
                      alt={quoteimage.alt}
                      style={{ width: 'auto', height: '4em' }}
                    />
                </div>
              </div>
            </div>
            <div className="column is-7">
              <div className="content">
                <div className="content has-text-centered">
                    <p className="quotation">{quotation.quote}</p>
                    <p className="quotationAuthor">{quotation.author}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section">               
      <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-12">
                <div className="content">
                  <div className="fancyHR">
                      <img
                          src={newspaper}
                          alt="Blog"
                          style={{ width: 'auto', height: '2em' }}
                        />
                      <hr/>
                  </div>
                  <div className="content has-text-centered">
                    <h3
                      className="has-text-weight-bold is-size-4-mobile is-size-4-tablet is-size-3-widescreen"
                      style={{
                        color: '#333',
                        lineHeight: '1',
                        padding: '0.25em'
                      }}>
                      Favorite Blog Posts
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12 homepage-featured-posts">
                {posts &&
                  posts.map(({ node: post }) => (
                    <BlogItem post={post} />
                ))}
              </div>
            </div>
          </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  heading: PropTypes.string,
  mainpitch: PropTypes.object,
  quoteimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array
  }),
  post: PropTypes.object
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout
      title={frontmatter.pagetitle}
      description={frontmatter.metadescription}
      >
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        heading={frontmatter.heading}
        mainpitch={frontmatter.mainpitch}
        quoteimage={frontmatter.quoteimage}
        description={frontmatter.description}
        intro={frontmatter.intro}
        post={data.allMarkdownRemark.edges[0].node}
        podcastbanner={frontmatter.podcastbanner}
        quotation={frontmatter.quotation}
        posts={data.allMarkdownRemark.edges}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    }),
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        pagetitle
        metadescription
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        mainpitch {
          title
          description
        }
        quoteimage {
         image {
            childImageSharp {
              fluid(maxWidth: 240, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          alt
        }
        podcastbanner {
          image {
            childImageSharp {
              fluid(maxWidth: 240, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          alt
          title
          description
        }
        quotation {
          author
          quote
        }
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___featuredpost, frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: 3
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
